import React from 'react';
import Layout from '../layout';
import { HeaderSimple } from '../components/headers';
import ContactImage from '../components/images/ContactImage';

const ContactPage = props => (
  <Layout>
    <div className="col-md-8 mx-auto p-0">
      <HeaderSimple title="Contact us for any enquiries" />
      <div className="row bg-neutralDark p-0 align-content-center">
        <div className="col-md-6 align-self-center">
          <div className="card bg-transparent border-0 text-center">
            <div className="card-body bg-transparent">
              <form
                name="contact"
                method="post"
                action="/success"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
              >
                <input type="hidden" name="bot-field" />
                <div className="form-group text-white">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className="form-control text-light bg-transparent p-3 border-bottom border-left-0 border-right-0 border-top-0 rounded-0 text-center"
                    name="name"
                    id="name"
                    required
                  />
                </div>
                <div className="form-group text-white">
                  <label htmlFor="email">Email</label>
                  <input
                    type="text"
                    className="form-control text-light bg-transparent p-3 border-bottom border-left-0 border-right-0 border-top-0 rounded-0 text-center"
                    name="email"
                    id="email"
                    required
                  />
                </div>
                <div className="form-group text-white">
                  <label htmlFor="message">Message</label>
                  <textarea
                    name="message"
                    className="form-control text-light bg-transparent p-3 border-bottom border-left-0 border-right-0 border-top-0 rounded-0 text-center"
                    id="message"
                    rows="6"
                    required
                  />
                </div>
                <input type="submit" className="btn m-4 btn-outline-light text-center rounded-0" value="Send Message" />
                <input type="reset" className="btn m-4 btn-outline-light text-center rounded-0" value="Clear" />
              </form>
            </div>
          </div>
        </div>
        <div className="col-md-6 p-0">
          <div className="card p-0 border-0 h-100 rounded-0 bg-dark text-white">
            <ContactImage />
          </div>
        </div>
      </div>
    </div>
  </Layout>
);
export default ContactPage;
